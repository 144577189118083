<template>
  <div class="industry">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>工业智能化</h3>
      <p>Industrial intelligence</p>
    </div>
    <!-- head -->
    <!-- content -->
    <Content :data="content" />
    <!-- content -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [
        {
          imgSrc: require("@/assets/img/电子签章代理授权.png"),
          title: "电子签章与电子合同系统",
          text: "电子签章系统是以PKI/PMI技术为基础，为各类业务应用信息系统提供电子签章/电子签名应用安全认证服务的平台型系统。<br>秀圆果公司于2022年与北京安证通信息科技股份有限公司达成战略合作，授权销售“安证通”电子签章系列产品。<br>安证通电子签章产品广泛应用于政府单位、央企、国企、大型企业集团、中小微企业等。截至2022年6月，安证通已累计为200+政府客户、500万+企业用户、2000万+个人用户提供了电子签名、电子签章、电子合同、数据保全等服务。",
          careArr: [
            "电子签名认证服务：为平台用户提供签名全流程的身份认证和签名证书认证；",
            "企业内部签章服务：平台提供“文档签章管理”、“用章审批管理”等服务，实现文件签署管理；",
            "电子合同会签服务：平台支持多方合同会签，合同发起人可以自己选择和调整对签对象和会签次序；",
            "电子合同验证服务：已集成国内所有第三方CA机构证书，可对加盖电子印章所有第三方证书验证。",
          ],
          iconArr: [require("@/assets/img/icon24.png")],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .industry {
    position: relative;
    height: 100%;
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../../assets/img/Industry-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.45rem;
        margin-left: 7.1rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .industry {
    height: 100%;
    position: relative;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../../assets/img/Industry-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        //width: 4.5rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
        text-align: center;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        text-align: center;
      }
    }
  }
}
</style>
